@import url(https://fonts.googleapis.com/css?family=Open+Sans);
*{
  font-family: 'Cormorant',serif;
}

.App {
  text-align: center;
  font-family: Cormorant;
  font-weight: lighter;
}

.App-baby {
  justify-content: "center";
  margin: "auto";
  white-space: "pre-wrap";
  max-width: 75vw;
  flex: 1 0 auto;
}

.App-all {
  background-color: #74AF98;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: gold;
  font-size: calc(10px + 2vmin);
}

.row {
  line-height: 0;
}


.top-right {
  position: fixed;
  top: 0px;
  right: 0px;
  height: 70vmin;
  width: 28vmin;
}

.bottom-left {
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 70vmin;
  width: 28vmin;
  flex-shrink: 0;
  margin-top: auto;
}

.App-header {
  letter-spacing: 6px;
  font-size: calc(10px + 3vmax);
  display: flex;
}

.App-home {
  margin-top: 1%;
  display: flex;
}

shadow
{
    display:block;
    position:relative;
}

.shadow:before
{
    display:block;
    content:'';
    position:absolute;
    height:34vmax;
    width:22vmax;
    -moz-box-shadow:inset 0px 0px 6px 6px rgba(246,214,202,255);
    -webkit-box-shadow:inset 0px 0px 6px 6px rgba(246,214,202,255);
    box-shadow:inset 0px 0px 6px 6px rgba(246,214,202,255);
}


.imgBorder{
  background: rgba(246,214,202,255);
  border: 1px solid rgba(246,214,202,255);
  border-radius: 5px 5px 5px 5px;
  padding: 5px;
} 

.home-pic {
  height: 34vmax;
  width: 22vmax;

}

.nursery-pic {
  height: 24vmax;
  width: 37vmax;
  margin-bottom: 2%;
}

.App-baby-pic {
  height: 18vmax;
  width: 23vmax;
  pointer-events: none;
  margin-bottom: 2%;
}

.App-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 2%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
